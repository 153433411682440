<template>
  <div class="info">
    <button class="round-btn" @click="closeModal">
      <img
        src="img/02_generales/cerrar.png"
        alt="close button"
      />
    </button>

    <div v-if="section" class="wrapper">
      <h2 class="title">
        {{ $t("s-" + section) }}
      </h2>
      <div class="content-wrapper" v-html="sectionDescription"></div>
    </div>
    <transition name="fade" mode="out-in">
      <Gallery :images="plainGallery" :index="selectedImageIndex" v-if="galleryIsShown"></Gallery>
    </transition>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery.vue";
const $ = require('jquery');
window.$ = $;
import { Analytic } from "../../public/js/analytics/Analytic.js";
var options = {
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Info",
};
var analytic = new Analytic(options);
export default {
  name: "Info",
  props: { section: { type: String, required: true } },
  components: {
    Gallery
  },
  async mounted() {
    await this.$nextTick();
    this.$root.$emit("initSidebarWith", this.section);
    this.$root.$emit("initPlayerWith", this.sectionAudio);
    var total = this.$store.state.numRoutes;
    $(".total").html(total);

    this.typeRoute = this.$store.state.typeRoute;

    if(this.typeRoute == 1 || this.typeRoute == null){
      $(".descLong").hide();
    }else{
      $(".descLong").show();
    }

    document.querySelectorAll('.imgA').forEach(item => {
      item.addEventListener('click', event => {
        console.log("click");
        this.selectedImageIndex = event.target.dataset.img;
        this.openGallery();
      })
    })
  },
  deactivated() {
    analytic.registerEvent(`Info: ${this.section}`, "closed");
  },
  computed: {
    sectionDescription() {
      analytic.registerEvent(`Info: ${this.section}`, "open");
      if(this.section == "discover"){
        return this.$store.state.parkData["discoverLong"].text;
        /*if(this.typeRoute == 1 || this.typeRoute == null){
          return this.$store.state.parkData["discover"].text;
        }else{
          return this.$store.state.parkData["discoverLong"].text;
        }*/
      }else{
        return this.$store.state.parkData[this.section].text;
      }
    },
    sectionAudio() {
      return this.$store.state.parkData[this.section].audio;
    },
    plainGallery() {
      if (this.gallery == null || this.gallery == undefined) {
        return [];
      }

      return this.gallery.map((item) => {
        console.log(item.src);
        return require(`@/assets/info/${item.src}`);
      });
    },
  },
  watch: {
    $route: function () {
      this.$root.$emit("initPlayerWith", this.sectionAudio);
    },
    "$store.state.parkData": function () {
      this.$root.$emit("initPlayerWith", this.sectionAudio);
    },
  },
  data() {
    return {
      parkData: null,
      galleryIsShown: false,
      selectedImage: null,
      selectedImageIndex: null,
      gallery: [],
      typeRoute: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeInfo");
    },
    openGallery() {
      this.gallery = [];
      this.gallery.push({ "src": "Intersect@2x.png" }, { "src": "Intersect@2x-1.png" }, { "src": "Screenshot 2021-09-17 at 12.51 1@2x.jpg" });
      this.galleryIsShown = true;
    },
    closeGallery() {
      this.galleryIsShown = false;
    },
  },
};
</script>

<style lang="scss">
.info {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--app-bg);
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000;

  .wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    margin: 0rem 1.7rem;
    overflow: hidden;
    max-height: 90%;

    .title {
      font-family: "Arial", sans-serif;
      font-size: 2rem;
      text-align: left;
      margin: 1rem 0 0 0;
      align-self: flex-start;
      color: #444444;
      &.special {
        font-family: "Righteous", monospace, sans-serif;
        &.centro {
          text-align: center;
          width: 100%;
          line-height: 2rem;
        }
      }
    }

    .content-wrapper {
      position: relative;
      flex: 1;
      margin: 1rem 0;
      width: 100%;
      overflow: auto;
      text-align: initial;
      font-size: 1.2rem;
      line-height: 1.6rem;
      padding-right: 1.5rem;
      mask-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 2%,
        rgba(0, 0, 0, 1) 98%,
        rgba(0, 0, 0, 0) 100%
      );
      -webkit-mask-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 2%,
        rgba(0, 0, 0, 1) 98%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 1rem 0rem;
      align-items: center;
      display: flex;
      flex-flow: column;
      p {
        text-align: center;
        font-size: 1.25rem;
        line-height: 2rem;
        margin-bottom: 3rem;
        &:last-of-type {
          padding-bottom: 1rem;
        }
        span {
          display: inline-block;
          width: 45%;
          text-align: right;
          &.center1 {
            text-align: center;
            font-family: "Arial", monospace, sans-serif;
            margin-bottom: 1rem;
            border-bottom: 4px solid #e4a709;
          }
        }
        &.center {
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 2rem;
          &:last-of-type {
            padding-bottom: 1rem;
          }
        }
        &.left {
          text-align: left;
          padding-right: 0.8rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          &:last-of-type {
            padding-bottom: 1rem;
          }
        }
        &.center1 {
          align-self: center;
          text-align: center;
          &:last-of-type {
            padding-top: 4rem;
          }
        }
      }
      span.center1 {
        text-align: center;
        font-size: 1.5rem;
        font-family: "Arial", monospace, sans-serif;
        margin-bottom: 1rem;
        border-bottom: 4px solid #e4a709;
        color: #444444;
      }
      img {
        margin-bottom: 2rem;
        &.img {
          width: 100%;
        }
        &.img1 {
          width: 100px;
          &.sub2 {
            margin: 0 1rem;
          }
        }
        &.img2 {
          width: 100%;
        }
        &.img3 {
          width: 100%;
        }
        &.gabinete {
          width: 85% !important;
        }
        &.imgw {
          width: 127px;
        }
        &.imgcm {
          width: 167px;
        }
      }
    }
  }
}
</style>
