<template>
  <div class="quiz-images">
    <div v-if="question" class="question-wrapper">
      <div class="wrapper">
        <p>{{ question }}</p>
      </div>

      <div class="pieceContainer">
        <div class="pieceCont">
          <transition name="fade">
            <img v-if="displayPiece" :src="showPiece" />
          </transition>
        </div>
        <div class="puzzleTimer">
          <div class="ptTop">{{ $t("challenge.puzzle") }}</div>
          <div class="ptBottom">
            <img src="img/03_onboarding/sr_time.png" alt="">
            <div class="contTimer"><span id="minutes">0</span>:<span id="seconds">00</span></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="pieces" class="answers-wrapper images">
      <img class="background" :src="fullBack" />
      <div class="correctPieces">
        <img
          v-for="image in piecesPaths"
          :key="image.id"
          :src="image.normal"
          :id="'c' + image.id"
          class="hidden"
        />
      </div>
      <div class="container">
        <div class="grid">
          <div
            v-for="number in piecesTotal"
            :key="number"
            class="piece"
            :data-opt="number"
            :id="'p' + number"
            v-on:click="chosenAnswer"
          ></div>
        </div>
      </div>
    </div>

    <div class="animation-container" v-if="hasFinalAnimation && showAnimation">
      <video
        ref="animationVideo"
        @ended="finishedAnimation()"
        autoplay
        muted
        playsinline
      ></video>
    </div>
  </div>
</template>

<script>
import { Analytic } from "../../public/js/analytics/Analytic.js";
import { Howl } from "howler";
var options = {
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Puzzle",
};
var analytic = new Analytic(options);
export default {
  name: "ImagePuzzle",
  components: {},
  props: {
    challengeId: {
      type: Number,
      required: true,
    },
    challenge: {
      type: Object,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    pieces: {
      type: Object,
      required: true,
    },
    onFinished: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
      piecesPaths: [],
      fullBack: null,
      currentPiece: 0,
      correctPieces: 0,
      displayPiece: true,
      showAnimation: false,
      success: null,
      Interval: null,
      seconds: 0, 
      minutes: 0,
      appendMinutes: null,
      appendSeconds: null,
    };
  },
  mounted() {
    this.appendMinutes = document.getElementById("minutes");
    this.appendSeconds = document.getElementById("seconds");
  },
  beforeMount() {
    let route = this.$store.state.currentRoute;
    this.pieces.parts.forEach((piece) => {
      var altpiece = {
        id: piece.id,
        normal: require(`@/assets/puzzlefotos/${route.id}/${piece.normal}`),
        separado: require(`@/assets/puzzlefotos/${route.id}/separados/${piece.normal}`),
      };
      this.piecesPaths.push(altpiece);
    });
    this.fullBack = require(`@/assets/puzzlefotos/${route.id}/${this.pieces.full}`);
    this.piecesPaths = this.shuffle(this.piecesPaths);
    analytic.registerEvent("ImgPuzzle", "open");

    this.success = new Howl({
      src: ["audios/desafio_correcto.mp3"],
      loop: false,
      volume: 0.7
    });
    clearInterval(this.Interval);
    setTimeout(() => {
      this.Interval = setInterval(this.startTimer, 1000);
    }, 1000);
    
  },
  computed: {
    hasFinalAnimation() {
      return (
        this.challenge.finishedAnimation != undefined &&
        this.challenge.finishedAnimation != null
      );
    },
    animationSrc() {
      return require(`@/assets/${this.challenge.finishedAnimation}`);
    },
    challengeAudio() {
      return this.challenge.audio;
    },
    challengeFinishedAudio() {
      return this.challenge.finishedAudio;
    },
    piecesTotal() {
      return this.piecesPaths.length;
    },
    showPiece() {
      if (this.currentPiece < this.piecesTotal) {
        return this.piecesPaths[this.currentPiece]["separado"];
      } else {
        return this.piecesPaths[0]["separado"];
      }
    },
  },
  methods: {
    startTimer () {
      this.seconds++; 
      
      if(this.seconds <= 9){
        this.appendSeconds.innerHTML = "0" + this.seconds;
      }
      
      if (this.seconds > 9){
        this.appendSeconds.innerHTML = this.seconds;
      } 
      
      if (this.seconds > 59) {
        this.minutes++;
        this.appendMinutes.innerHTML = this.minutes;
        this.seconds = 0;
        this.appendSeconds.innerHTML = "0" + 0;
      }
    },
    shuffle(a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    },
    chosenAnswer(evt) {
      var clicked = evt.target.dataset.opt;
      var current = this.piecesPaths[this.currentPiece];
      if (clicked == current.id) {
        this.$root.$emit("togglePointerEvents", false);
        this.displayPiece = false;
        this.correctPieces = this.correctPieces + 1;
        let unhide = this.$el.querySelector(`img#c${clicked}`);
        unhide.classList.remove("hidden");
        setTimeout(() => {
          this.currentPiece = this.currentPiece + 1;
          if (this.correctPieces < this.piecesTotal) {
            this.displayPiece = true;
          }
          this.$root.$emit("togglePointerEvents", true);
        }, 500);
      }

      if (this.correctPieces == this.piecesTotal) {
        this.$root.$emit("togglePointerEvents", false);
        let saveObj = {
          id: this.$store.state.currentRoute.prize,
          challenge: this.challengeId,
          correct: true,
        };

        var pieceCont = document.querySelector(".pieceCont");
        pieceCont.style.display = "none";

        this.success.play();
        clearInterval(this.Interval);
        if (
          !this.containsObject(saveObj, this.$store.state.finishedChallenges)
        ) {
          this.$store.commit("registerFinishedChallenge", saveObj);
        }

        var todasRutas = this.$store.state.finishedRoutes;
        if((todasRutas.includes("entrada") == false)){
          this.$store.state.finishedRoutes.push("entrada");
            let saveObjE = {
              id: "cam_1",
              challenge: 1,
              correct: true,
            };

            this.$store.commit("registerFinishedChallenge", saveObjE);
          
          this.$store.commit("entradaState", true);
        }

        if (this.hasFinalAnimation) {
          this.$store.commit("toggleDisabledLanguages", true);
          this.showAnimation = true;
          setTimeout(() => {
            this.$refs.animationVideo.setAttribute("src", this.animationSrc);
            this.$refs.animationVideo.load();
            this.$refs.animationVideo.play();
          }, 1000);
        } else {
          this.finishedChallenge();
        }
      }
    },
    finishedAnimation() {
      this.$store.commit("toggleDisabledLanguages", false);
      setTimeout(() => {
        this.finishedChallenge();
      }, 1000);
    },
    finishedChallenge() {
      setTimeout(() => {
        this.showAnimation = false;
        this.$root.$emit("togglePointerEvents", true);
        var routeId = this.$store.state.currentRoute.id;
        analytic.registerEvent(`ImgPuzzle: ${routeId}`, ` result: true`);
        this.onFinished(true);
      }, 1500);
    },
    containsObject(obj, list) {
      return list.find((item) => item.id === obj.id) !== undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
$small: 300px;
$medium: 600px;

.quiz-images {
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  max-height: 90%;
  flex-flow: column;
  justify-content: center;
  background-color: var(--app-bg);

  .question-wrapper {
    margin-bottom: unset;
    overflow: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
    color: #444444;
    @media screen and (max-height: $medium) {
      // justify-content: initial;
      margin-bottom: 1rem;
      justify-content: space-between;
    }

    .wrapper {
      text-align: initial;

      p {
        font-family: "Arial-Black", monospace, sans-serif;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 700;
        width: 100%;
        margin-bottom: unset;
        @media screen and (max-height: $medium) {
          // margin-bottom: 1rem;
        }
      }
    }

    div.pieceContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      //justify-content: space-evenly;
      height: 90px;
      //width: 90px;
      width: 100%;

      .puzzleTimer {
        display: flex;
        align-items: center;
        flex-flow: column;
        width: 58%;

        .ptTop{
          margin-bottom: 0.5rem;
          font-size: 18px;
        }

        .ptBottom{
          display: flex;
          align-items: center;

          img{
            width: 20px;
            height: 20px;
            object-fit: contain;
            object-position: center;
          }

          .contTimer{
            margin-left: 5px;
            font-size: 20px;
          }
        }
      }

      .pieceCont{
        height: 100%;
        width: 38%;
        display: block;

        img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
      }
    }
  }

  .answers-wrapper {
    position: relative;
    height: auto;
    padding-top: 57%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    //flex: 1;
    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .correctPieces {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-top: 57%;
      img {
        position: inherit;
        width: 100%;
        top: 0;
        left: 0;
        transition: opacity 0.5s ease-in-out;
        &.hidden {
          opacity: 0;
        }
      }
    }
    .container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-top: 57%;
      display: grid;
      .grid {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-template-rows: repeat(3, 33%);
        .piece {
          width: 100%;
          height: 0px;
          display: flex;
          overflow-x: auto;
          overflow-y: hidden;
          min-width: 0;
          min-height: 0;
          padding-top: 100%;
        }
      }
    }
  }

  .animation-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      position: relative;
      width: 100%;
      height: auto;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
