<template>
    <div class="rewards">
      <button class="round-btn round-btn-rewards" @click="closeModal">
        <img
          src="img/02_generales/cerrar.png"
          alt="close button"
        />
      </button>
  
      <div class="wrapper">
        <h2 class="title">{{ $t("reward-title") }}</h2>
        <p class="topmargin" v-html="$t('reward-legend')"></p>
        <div v-if="prizes" class="content-wrapper">
          <figure v-for="prize in currentPrizes" :key="prize.id" :data-route="prize.route" class="reward"
            :class="{ 'final-reward': prize.id === 14 }" @click="openRoute">
            <div class="helper" v-if="prize.id !== 14">
              <img class="prize" :src="getBadgeSource(prize)" alt="on prize image" />
            </div>
  
            <div class="helper" v-if="prize.id === 14">
              <img class="prize final-prize" :src="getOnBadge(prize.img)" alt="on prize image" />
            </div>
            <span class="prize-name" v-html="prize.name"></span>
          </figure>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Analytic } from "../../public/js/analytics/Analytic.js";
  // import NextRoute from "@/js/NextRoute.js";
  import Park from "@/models/Park.js";
  
  const $ = require('jquery');
  window.$ = $;
  
  var options = {
    url: "./setAnalytic.php",
    idPag: 1,
    namePag: "Route",
  };
  var analytic = new Analytic(options);
  
  export default {
    name: "Rewards",
    mounted() {
      if (this.$store.state.parkData) {
        this.prizes = this.getNewPrizes();
        this.park = new Park(this.$store.state.parkData);
      }
  
      this.route = this.$store.state.currentRoute;
      analytic.registerEvent(`Prizes`, "open");
  
      this.totalPoints = this.$store.state.numRoutes;
  
      console.log("PREMIOS");
    },
    watch: {
      "$store.state.parkData": function () {
        this.prizes = this.getNewPrizes();
      },
    },
    data() {
      return {
        route: null,
        nextRoute: null,
        prizes: null,
        park: null,
        detailShown: false,
        detailId: null,
        totalPoints: null,
      };
    },
    deactivated() {
      analytic.registerEvent("Prizes", "closed");
      console.log("PREMIOS CERRADOS");
    },
    methods: {
      getPrizes() {
        return this.$store.state.parkData.prizes;
      },
      getNewPrizes() {
        var typeR = this.$store.state.typeRoute;
        var total = this.$store.state.numRoutes;
        $(".total").html(total);
        if (typeR == 2) {
          var newPrizes = [];
          var oldPrizes = this.$store.state.parkData.prizes;
          oldPrizes.map((a) => {
            if (a.typeRoute != 1) {
              newPrizes.push({
                id: a.id,
                img: a.img,
                name: a.name,
                route: a.route,
                typeRoute: a.typeRoute,
              });
            }
          });
          return newPrizes;
        } else {
          if(typeR == null){
            $(".total").html("7");
          }
          var newPrizes0 = [];
          var oldPrizes0 = this.$store.state.parkData.prizes;
          oldPrizes0.map((a) => {
            if (a.typeRoute != 2) {
              newPrizes0.push({
                id: a.id,
                img: a.img,
                name: a.name,
                route: a.route,
                typeRoute: a.typeRoute,
              });
            }
          });
          return newPrizes0;
          //return this.$store.state.parkData.prizes;
        }
      },
      getStatus(prize) {
        let found = this.$store.state.finishedChallenges.filter((finished) => {
          return finished.id === prize.img;
        });
  
        if (found.length > 0) {
          return {
            isAchieved: true,
            isCorrect: found[0].correct,
          };
        } else {
          return {
            isAchieved: false,
            isCorrect: false,
          };
        }
      },
      isCorrect(name) {
        if (!this.isAchieved(name)) {
          return false;
        }
        let found = this.$store.state.finishedChallenges.filter((finished) => {
          return finished.id === name;
        })[0];
        return found.correct;
      },
      getBadgeSource(prize) {
        let badgeStatus = this.getStatus(prize);
        if (badgeStatus.isAchieved) {
          return this.getOnBadge(prize.img);
        } else {
          return this.getOffBadge(prize.img);
        }
      },
      getOffBadge(name) {
        return require(`@/assets/06_camafeos/${name}_off.png`);
      },
      getOnBadge(name) {
        return require(`@/assets/06_camafeos/${name}_on.png`);
      },
      closeModal() {
        this.$router.go(-1);
      },
      openRoute(e) {
        /*if (e.target.classList.contains("final-reward")) {
          return;
        }*/
  
        if (this.route) {
          let clickedRouteId = e.target.getAttribute("data-route");
          let isBadgeAchieved =
            this.$store.getters.routeWasFinished(clickedRouteId);
          if (isBadgeAchieved) {
            analytic.registerEvent("Prizes", `Obtained badge: ${clickedRouteId}`);
            this.pushRouteTo("Route", { id: clickedRouteId });
          } else {
            if (e.target.classList.contains("final-reward")) {
              if(this.$route.path.toLowerCase() == "/complete"){
                location.reload();
              }else{
                this.closeModal();
                //this.$root.$off("initSidebarWith");
                //this.$root.$off("forceSidebar");
                this.$router.push("Complete");
              }
            } else {
              analytic.registerEvent(
                "Prizes",
                `Unobtained badge: ${clickedRouteId}`
              );
              this.pushRouteTo("Map", { type: "spot", spot: clickedRouteId });
            }
            /*analytic.registerEvent(
              "Prizes",
              `Unobtained badge: ${clickedRouteId}`
            );
            this.pushRouteTo("Map", { type: "spot", spot: clickedRouteId });*/
          }
        }
      },
      pushRouteTo(route, params) {
        if (params) {
          this.$router.push({ name: route, query: params }).catch(() => { });
        } else {
          this.$router.push(route, params).catch(() => { });
        }
      },
    },
    beforeDestroy() {
      this.$root.$off("initSidebarWith");
      this.$root.$off("forceSidebar");
    },
    computed: {
      currentPrizes() {
        const scrollDemo = document.querySelector(".content-wrapper");
        if(scrollDemo){
          scrollDemo.addEventListener("scroll", function() {
          console.log(scrollDemo.scrollTop);
        }, { passive: true });
        }

        console.log(scrollDemo);

        console.log("loop");
        if (this.$store.getters.allChallengesDone || this.$store.getters.minPointsComplete) {
          return this.prizes;
        } else {
          return this.prizes.filter((p) => p.id !== 14);
        }
      },
    },
  };
  </script>
  
  <style lang="scss">
  .rewards {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--app-bg);
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1000;

    .round-btn-rewards{
      left: unset;
      right: 1rem;
    }
  
    .wrapper {
      position: relative;
      display: flex;
      flex: 1;
      flex-flow: column;
      align-items: center;
      margin: 0rem 1.7rem 0rem 1.7rem;
      overflow: hidden;
      max-height: 90%;
  
      .title {
        font-family: "Arial", monospace, sans-serif;
        font-size: 2rem;
        text-align: left;
        margin: 1rem 0 0 0;
        align-self: flex-start;
        color: #444444;
      }
      .topmargin {
        margin-top: 1rem;
      }
  
      .content-wrapper {
        position: relative;
        margin: 2rem 0 0 0;
        padding: 1rem 0;
        width: 100%;
        height: auto;
        overflow: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        gap: 0.5rem 0.5rem;
        mask-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 2%,
          rgba(0, 0, 0, 1) 98%,
          rgba(0, 0, 0, 0) 100%
        );
        -webkit-mask-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 2%,
          rgba(0, 0, 0, 1) 98%,
          rgba(0, 0, 0, 0) 100%
        );
  
        .reward {
          position: relative;
          display: flex;
          flex-flow: column;
          height: 12rem;
          order: 1;
  
          &.final-reward {
            order: 0;
  
            .prize-name {
              font-size: 1rem;
            }
          }
  
          .helper {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 7rem;
            flex: 1;
            pointer-events: none;
          }
  
          .prize {
            position: absolute;
            width: 80%;
            height: 100%;
            object-fit: contain;
            pointer-events: none;
          }
  
          .prize-name {
            position: relative;
            flex: 0;
            min-height: 3rem;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            font-size: 0.8rem;
            font-family: "Righteous", monospace, sans-serif;
            text-transform: uppercase;
            pointer-events: none;
            color: #444444;
          }
        }
      }
    }
  }
  </style>
  