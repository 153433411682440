<template>
  <div class="next-route">
    <div class="wrapper">
      <div class="next-challenge-info" v-if="this.nextRouteInfo">
        <span class="next-challenge">{{ $t("next-challenge") }}</span>
        <span class="info-name">{{ this.nextRouteInfo.name }}</span>
        <span class="info-length" v-if="routeInformation">{{
          $t("aprox-distance", {
            distance: routeInformation.distance,
          })
        }}</span>
        <span class="info-duration" v-if="routeInformation">{{
          $t("aprox-time", {
            time: routeInformation.time,
          })
        }}</span>
        <span class="info-slope" v-if="routeInformation">{{
          $t("aprox-slope", {
            slope: routeInformation.slope,
          })
        }}</span>

        <p class="info-desc" v-html="routeInformation.info.desc"></p>

        <div v-if="gallery" class="gallery">
          <div
            v-for="(chunk, index) in getGalleryLayoutContainers"
            :key="index"
            class="layout-wrapper"
            :class="{ special: gallery.length <= 2 }"
          >
            <div v-for="(item, i) in chunk" :key="i" class="item">
              <img
                :src="getImage(item)"
                :data-id="i"
                class="image"
                @click="openGallery"
              />
            </div>
          </div>
          <figure class="galleryImg">
            <img src="img/galeria_btn.png" alt="">
          </figure>
        </div>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <Gallery
        :images="plainGallery"
        :index="selectedImageIndex"
        v-if="galleryIsShown"
      ></Gallery>
    </transition>

    <div class="buttons-container">
      <button class="btn" @click="advanceToMap">{{ $t("route") }}</button>
      <button class="btn" @click="advanceToQR">{{ $t("qr") }}</button>
    </div>
  </div>
</template>

<script>
import Park from "@/models/Park.js";
import { Analytic } from "../../public/js/analytics/Analytic.js";
import Gallery from "@/components/Gallery.vue";

var analytic = new Analytic({
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "NextRoute",
});

export default {
  name: "NextRoute",
  components: { Gallery },
  watch: {
    "$store.state.locale": function () {
      let routeId = this.$store.state.routeId;
      this.park = new Park(this.$store.state.parkData);

      this.route = this.park.getRouteWithId(routeId);
      this.$store.commit("currentRoute", this.route);
      this.updateNextRouteData();
    },
  },
  mounted() {
    let routeId = this.$store.state.routeId;
    this.park = new Park(this.$store.state.parkData);

    if (routeId == "entrada") {
      analytic.registerEvent("Next Route", "entrada");
    } else {
      analytic.registerEvent("Next Route", routeId);
    }
    this.updateNextRouteData();
  },
  data() {
    return {
      park: null,
      ready: false,
      nextRoute: null,
      nextRouteInfo: null,
      currentIndex: null,
      gallery: [],
      galleryIsShown: false,
      selectedImage: null,
      selectedImageIndex: null,
      routeInformation: null,
    };
  },
  methods: {
    advanceToMap() {
      this.$router.push({ name: "Map", query: { type: "route" } });
    },
    advanceToQR() {
      this.$router.push("Scanner");
    },
    getImage(img) {
      return require(`@/assets/images/${img}`);
    },
    closeLightbox() {
      setTimeout(() => {
        this.currentIndex = null;
      }, 250);
    },
    updateNextRouteData() {
      let id = this.$store.state.nextRoute.properties.endId;
      this.nextRouteInfo = this.park.getNextRouteInfo(id);

      //get actual info for the next route
      let currentRoute = this.$store.state.currentRoute.id;
      let nextRoute = this.nextRouteInfo.id;
      this.routeInformation =
        this.park.routeInformation[currentRoute][nextRoute];

      this.gallery = this.routeInformation.info.images;
    },
    openGallery(e) {
      this.selectedImage = e.target.src;
      this.selectedImageIndex = e.target.getAttribute("data-id");
      this.galleryIsShown = true;
    },
    closeGallery() {
      this.galleryIsShown = false;
    },
  },
  computed: {
    plainGallery() {
      if (this.gallery == null || this.gallery == undefined) {
        return [];
      }

      return this.gallery.map((item) => {
        return require(`@/assets/images/${item}`);
      });
    },
    getGalleryLayoutContainers() {
      let galleryCopy = this.routeInformation.info.images;
      var containers = [];
      for (var i = 0; i < galleryCopy.length; i += 5) {
        containers.push(galleryCopy.slice(i, i + 3));
      }

      return containers;
    },
  },
};
</script>

<style lang="scss" scoped>
.next-route {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    margin: 7.5rem 2.5rem 0rem 2.5rem;
    overflow: auto;

    .next-challenge-info {
      position: relative;
      display: flex;
      flex-flow: column;
      width: 100%;
      padding: 1rem 0rem;
      overflow: auto;
      mask-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 2%,
        rgba(0, 0, 0, 1) 98%,
        rgba(0, 0, 0, 0) 100%
      );
      -webkit-mask-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 2%,
        rgba(0, 0, 0, 1) 98%,
        rgba(0, 0, 0, 0) 100%
      );

      span {
        text-align: initial;
      }

      .next-challenge {
        font-family: "Arial-black", monospace, sans-serif;
        font-weight: 700;
        font-size: 1.375rem;
        margin: 1rem 0rem;
        text-transform: uppercase;
        color: #444444;
      }

      .info-name {
        font-family: "Arial-black", monospace, sans-serif;
        font-weight: 700;
        font-size: 28px;
        color: #444444;
      }

      .info-length,
      .info-duration,
      .info-slope {
        font-family: "Arial", monospace, sans-serif;
        font-weight: 400;
        font-size: 1.3rem;

        &.info-length {
          margin-top: 2rem;
        }

        &.info-slope {
          margin-bottom: 2rem;
        }
      }

      .info-desc {
        font-size: 1.25rem;
        line-height: 2rem;
      }

      .gallery {
        margin: 0 auto;
        width: 100%;
        position: relative;

        .galleryImg{
          width: 2.6rem;
          height: 2.6rem;
          pointer-events: none;
          position: absolute;
          bottom: 0;
          right: 0.25rem;
          animation: pulse 2s linear infinite;

          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }

        /* Gallery */
        .layout-wrapper {
          position: relative;
          //display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr;
          gap: 0.5rem;

          &:not(:first-of-type) {
            margin-top: 0.5rem;
          }

          &.special {
            .item {
              &:nth-child(3n + 1) {
                grid-column: 1;
                grid-row: 1 / span 1;
              }
            }
          }

          .item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 12rem;
            overflow: hidden;

            .image {
              position: absolute;
              width: 100%;
              height: 100%;
              margin: 0;
              object-fit: cover;
              object-position: center;
            }

           &:nth-child(3n + 1) {
              grid-column: 1;
              grid-row: 1 / span 2;
            }

            &:nth-child(3n + 2) {
              grid-column: 2 / span 1;
              grid-row: 1 / span 1;
              display: none;
            }

            &:nth-child(3n + 3) {
              grid-column: 2 / span 1;
              grid-row: 2 / span 1;
              display: none;
            }
            &:nth-child(3n + 4) {
              display: none;
            }
            &:nth-child(3n + 5) {
              display: none;
            }
          }
        }
      }
    }
  }

  .buttons-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 2.5rem;

    .btn {
      position: relative;
      font-size: 1.6rem;
      padding: 1rem;
      font-family: "Arial-black", monospace, sans-serif;
      flex: 0.45;
      width: auto;
      border: unset;
      border-radius: 0.5rem;
      background-color: var(--app-orange);
      color: #444444;
      box-shadow: 0px 3px 4px 0px darkgrey;
    }
  }
}
</style>
