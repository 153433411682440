<template>
  <div
    class="top-menu"
    :class="{ disabled: this.$store.state.disabledLanguages }"
  >
    <button
      v-if="help"
      class="menu-btn help-opt"
      :data-id="options[3].id"
      @click="onClickButton"
    >
      <img :src="options[3].icon" alt="button image" />
    </button>
    <button
      v-if="language"
      class="menu-btn language-opt"
      :data-id="options[0].id"
      @click="onClickButton"
    >
      <img :src="options[0].icon" alt="button image" />
    </button>

    <button
      v-if="map"
      class="menu-btn map-opt"
      :data-id="options[1].id"
      @click="onClickButton"
    >
      <img :src="options[1].icon" alt="button image" />
    </button>

    <button
      v-if="scanner"
      class="menu-btn scanner-opt"
      :data-id="options[2].id"
      @click="onClickButton"
    >
      <img :src="options[2].icon" alt="button image" />
    </button>
  </div>
</template>

<script>
export default {
  name: "top-menu",
  mounted() {
    this.updateLangImage();
  },
  props: {
    language: {
      type: Boolean,
      default: true,
    },
    map: {
      type: Boolean,
      default: true,
    },
    help: {
      type: Boolean,
      default: true,
    },
    sound: {
      type: Boolean,
      default: false,
    },
    scanner: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lang: this.$store.lang,
      options: [
        {
          id: 1,
          icon: null,
          opt: {
            es: "./img/botonera/language_es.png",
            en: "./img/botonera/language_en.png",
            pt: "./img/botonera/language_pt.png",
            fr: "./img/botonera/language_fr.png",
          },
        },
        {
          id: 2,
          icon: "./img/botonera/location.png",
        },
        {
          id: 3,
          icon: "./img/botonera/qr.png",
        },
        {
          id: 4,
          icon: "./img/onboarding/on_help.png",
        },
      ],
    };
  },
  methods: {
    onClickButton(evt) {
      this.$emit("clicked", evt.target.dataset.id);

      if (evt.target.dataset.id == 1) {
        this.updateLangImage();
      }
    },
    updateLangImage() {
      let locale = this.$store.state.locale;
      this.options[0].icon = this.options[0].opt[locale];
    },
  },
};
</script>

<style lang="scss" scoped>
.top-menu {
  display: flex;
  flex-flow: row-reverse;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
  width: 73%;
  justify-content: space-between;

  &.disabled {
    pointer-events: none;
  }

  .menu-btn {
    position: relative;
    width: 2.6rem;
    height: 2.6rem;
    border: none;
    margin: 0rem 0.2rem 0.2rem 0.2rem;
    background-color: transparent;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }
}
</style>
