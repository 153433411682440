<template>
  <div class="quiz">
    <div v-if="question" class="question-wrapper">
      <p v-html="question"></p>
    </div>
    <div v-if="answers" class="answers-wrapper">
      <div class="check-answers" v-for="ans in answers" v-bind:key="ans.id">
        <figure class="audio" :id="ans.id" v-on:click="playAudio(ans.audio, ans.id)"><img
            src="@/assets/bocina.png" alt=""></figure>
        <big-button ref="answer" :data-opt="ans.id" :onClick="chosenAnswer" type="answer" :allCaps="false"
          :selectable="true" :class="{ selected: ans.id === selected }" class="disabled">{{ ans.answer }}
        </big-button>
      </div>
    </div>
  </div>
</template>
  
<script>
import BigButton from "@/components/BigButton.vue";
import { Howl } from "howler";
import { Analytic } from "../../public/js/analytics/Analytic.js";

const $ = require('jquery');
window.$ = $;

var options = {
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "AudioTrivia",
};
var analytic = new Analytic(options);
export default {
  name: "AudioTrivia",
  components: {
    BigButton,
    //ARVisorGame,
  },
  props: {
    challengeId: {
      type: Number,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
    onFinished: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
      arVisorShown: false,
      audios: [],
      fail: null,
      success: null,
    };
  },
  mounted() {
    analytic.registerEvent("AudioTrivia", "open");
    // let route = this.$store.state.currentRoute;

    this.fail = new Howl({
      src: ["audios/desafio_incorrecto.mp3"],
      loop: false,
      volume: 0.7
    });
    this.success = new Howl({
      src: ["audios/desafio_correcto.mp3"],
      loop: false,
      volume: 0.7
    });
  },
  computed: {
    challengeAudio() {
      return this.challenge.audio;
    },
    challengeFinishedAudio() {
      return this.challenge.finishedAudio;
    },
  },
  methods: {
    playAudio: function (audio, id) {
      $(".audio img").attr("src", require("@/assets/bocina.png")).removeClass("pulse");
      $(".audio").css("pointer-events", "all");
      $("#" + id + "").css("pointer-events", "none");
      $("#" + id + " img").attr("src", require("@/assets/bocina_seleccion.png")).addClass("pulse");
      if (this.currentSound != null) {
        this.currentSound.stop();
        this.currentSound.unload();
        this.currentSound = null;
      }
      this.audios.push(id);
      if (this.audios.includes(1) && this.audios.includes(2) && this.audios.includes(3)) {
        $(".big-button").removeClass("disabled");
      }

      this.currentSound = this.loadAudio(audio);
      this.currentSound.play();
    },
    loadAudio(audioName) {
      console.log("will load", audioName);
      return new Howl({
        src: ["audios/" + audioName],
        loop: false,
        volume: 0.8,
        onload: function () {
          console.log("loaded!");
          //this.currentSound.play();
        },
        onend: function () {
          $(".audio img").attr("src", require("@/assets/bocina.png")).removeClass("pulse");
          $(".audio").css("pointer-events", "all");
        }
      });
    },
    pushRouteTo(route, params) {
      if (params) {
        this.$router.push({ name: route, query: params }).catch(() => { });
      } else {
        this.$router.push(route, params).catch(() => { });
      }
    },
    shuffle(a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    },
    chosenAnswer(evt) {
      this.currentSound.stop();
      $(".audio img").attr("src", require("@/assets/bocina.png")).removeClass("pulse");
      this.$root.$emit("togglePointerEvents", false);
      let chosen = this.answers.find((ans) => ans.id == evt.target.dataset.opt);
      this.selected = chosen.id;

      let saveObj = {
        id: this.$store.state.currentRoute.prize,
        challenge: this.challengeId,
        correct: chosen.isCorrect,
      };

      if(chosen.isCorrect){
        this.success.play();
      }else{
        this.fail.play();
      }

      if (!this.containsObject(saveObj, this.$store.state.finishedChallenges)) {
        if (this.challengeId == 1) {
          this.$store.commit("registerFinishedChallenge", saveObj);
        }
        this.$store.commit("registerFinishedChallenge", saveObj);
      }
      setTimeout(() => {
        var routeId = this.$store.state.currentRoute.id;
        analytic.registerEvent(
          `AudioTrivia: ${routeId}`,
          ` result: ${chosen.isCorrect}`
        );
        this.$root.$emit("togglePointerEvents", true);
        this.onFinished(chosen.isCorrect);
      }, 1500);
      var todasRutas = this.$store.state.finishedRoutes;
      if((todasRutas.includes("entrada") == false)){
        this.$store.state.finishedRoutes.push("entrada");
          let saveObjE = {
            id: "cam_1",
            challenge: 1,
            correct: true,
          };

          this.$store.commit("registerFinishedChallenge", saveObjE);
        
        this.$store.commit("entradaState", true);
      }
    },
    containsObject(obj, list) {
      return list.find((item) => item.id === obj.id) !== undefined;
    },
  },
  beforeDestroy() {
    if (this.currentSound != null) {
      this.currentSound.stop();
      this.currentSound.unload();
      this.currentSound = null;
    }
  }
};
</script>
  
<style lang="scss" scoped>
.quiz {
  position: relative;
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: center;
  overflow: auto;

  .question-wrapper {
    margin: 0 0 1rem 0;
    padding: 1rem 1.5rem 0.5rem 0;
    overflow: auto;
    mask-image: linear-gradient(0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 4%,
        rgba(0, 0, 0, 1) 96%,
        rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image: linear-gradient(0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 4%,
        rgba(0, 0, 0, 1) 96%,
        rgba(0, 0, 0, 0) 100%);

    p {
        font-family: "Arial-Black", monospace, sans-serif;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 700;
        margin: 0;
        color: #444444;
    }
  }

  .answers-wrapper {
    display: flex;
    width: 100%;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    flex: 0.8;

    .selected{
      color: black !important;
      background-color: white !important;
      opacity: 1 !important;
    }

    .disabled {
      pointer-events: none;
      opacity: 0.5;
      //background: linear-gradient(180deg, #A1C3EB 0%, #5E91CC 9.38%);
      //color: rgba(248, 247, 243, 0.6);
    }

    .big-button {
      padding: 1.2rem;
      background-color: black;
      color: white;
    }

    .check-answers {
      display: flex;
      width: 100%;
      align-items: center;
      flex-flow: row;
      justify-content: space-evenly;
      flex: 0.8;

      .pulse {
        animation: pulse 4s infinite;
      }

      .audio {
        width: 25%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}
</style>
  