<template>
    <div class="intermediate">
      <div v-if="route" class="wrapper">
        <h2 class="title">{{ name }}</h2>
        <div class="content-wrapper">
          <p v-if="information" v-html="information"></p>
  
          <div v-if="gallery" class="gallery">
            <div
              v-for="(chunk, index) in getGalleryLayoutContainers"
              :key="index"
              class="layout-wrapper"
              :class="{ special: gallery.length <= 2 }"
            >
              <div v-for="(item, i) in chunk" :key="i" class="item">
                <img
                  :src="getImage(item.src)"
                  :data-id="i"
                  class="image"
                  @click="openGallery"
                />
              </div>
            </div>
            <figure class="galleryImg">
              <img src="img/galeria_btn.png" alt="">
            </figure>
          </div>
        </div>
  
        <big-button :onClick="advance0">{{ $t("next") }}</big-button>
      </div>
  
      <transition name="fade" mode="out-in">
        <Gallery
          :images="plainGallery"
          :index="selectedImageIndex"
          v-if="galleryIsShown"
        ></Gallery>
      </transition>
    </div>
  </template>
  
  <script>
  import NextRoute from "../js/NextRoute.js";
  import BigButton from "@/components/BigButton.vue";
  import { Analytic } from "../../public/js/analytics/Analytic.js";
  import Gallery from "@/components/Gallery.vue";
  
  var analytic = new Analytic({
    url: "./setAnalytic.php",
    idPag: 1,
    namePag: "Intermediate",
  });
  
  export default {
    name: "Intermediate",
    components: {
      BigButton,
      Gallery,
    },
    data() {
      return {
        routeId: null,
        name: null,
        park: null,
        route: null,
        information: null,
        gallery: null,
        challenge: null,
        currentIndex: null,
        isThisChallengeFinished: false,
        onlyDetail: false,
        galleryIsShown: false,
        selectedImage: null,
        selectedImageIndex: null,
        quinta: null,
        chalet: null,
        url: null,
        nextRoute: null,
      };
    },
    watch: {
      "$store.state.locale": function () {
        let typeRoute = this.$store.state.typeRoute;
        if(typeRoute != null){
          let nextrouteId = this.$store.state.currentRoute.id;
          this.nextRoute = this.$store.state.parkData.getRouteWithId(nextrouteId);
          this.$store.commit("currentRoute", this.nextRoute);
          // Helper objext to save nextroute into store
          let nextRouteHelper = new NextRoute(this.nextRoute);
          nextRouteHelper.getNextRouteData();
        }

        let routeId = this.$store.state.intermediate;
        this.route = this.$store.state.parkData.middlePoints.find((point) => point.id === routeId);
        console.log(this.route);
        this.updateData();
      },
    },
    mounted() {
        let typeRoute = this.$store.state.typeRoute;
        if(!this.$store.getters.allChallengesDone && !this.$store.getters.minPointsComplete){
          if(typeRoute != null){
          let nextrouteId = this.$store.state.currentRoute.id;
          this.nextRoute = this.$store.state.parkData.getRouteWithId(nextrouteId);
          this.$store.commit("currentRoute", this.nextRoute);
          // Helper objext to save nextroute into store
          let nextRouteHelper = new NextRoute(this.nextRoute);
          nextRouteHelper.getNextRouteData();
        }
        }

        let routeId = this.$store.state.intermediate;
        this.route = this.$store.state.parkData.middlePoints.find((point) => point.id === routeId);
        console.log(this.route);
        analytic.registerEvent("Intermedio", routeId);
        this.updateData();
    },
    computed: {
      fixedGallery() {
        return this.gallery.map((item) => {
          return {
            id: item.id,
            src: require(`@/assets/images/${item.src}`),
            description: item.description,
          };
        });
      },
      plainGallery() {
        if (this.gallery == null || this.gallery == undefined) {
          return [];
        }
  
        return this.gallery.map((item) => {
          return require(`@/assets/images/${item.src}`);
        });
      },
      getGalleryLayoutContainers() {
        let galleryCopy = this.gallery;
        var containers = [];
        for (var i = 0; i < galleryCopy.length; i += 5) {
          containers.push(galleryCopy.slice(i, i + 3));
        }
        return containers;
      },
    },
    methods: {
      advance0() {
        let next = this.$store.state.nextRoute;
        if(this.$store.getters.allChallengesDone || this.$store.getters.minPointsComplete){
          this.$router.push("Complete");
        }else{
          if (this.$store.state.onboardingState == null && next == null) {
            this.$router.push("/");
          } else {
              this.$router.push("NextRoute");
          }
        }
      },
      updateData() {
      this.$nextTick();
      this.name = this.route.name;
      this.information = this.route.desc;
      this.gallery = this.route.gallery;
    },
      getImage(name) {
        return require(`@/assets/images/${name}`);
      },
      openGallery(e) {
        this.selectedImage = e.target.src;
        this.selectedImageIndex = e.target.getAttribute("data-id");
        this.galleryIsShown = true;
      },
      closeGallery() {
        this.galleryIsShown = false;
      },
      goBack() {
        this.$router.go(-1);
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .intermediate {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    background-color: var(--app-bg);
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .big-button {
      color: #444444;
    }
  
    .wrapper {
      position: relative;
      display: flex;
      flex: 1;
      flex-flow: column;
      align-items: center;
      margin: 0rem 1.7rem;
      overflow: auto;
      padding-top: 7.5rem;
  
      .logo {
        position: relative;
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
  
        img {
          position: relative;
          height: auto;
          width: 100%;
        }
      }
  
      .title {
        font-size: 29px;
        text-align: left;
        align-self: flex-start;
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-weight: normal;
        color: #444444;
      }
  
      .content-wrapper {
        flex: 1;
        padding: 1rem 0 1rem 0;
        overflow: auto;
        mask-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 2%,
          rgba(0, 0, 0, 1) 98%,
          rgba(0, 0, 0, 0) 100%
        );
        -webkit-mask-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 2%,
          rgba(0, 0, 0, 1) 98%,
          rgba(0, 0, 0, 0) 100%
        );
  
        p {
          text-align: initial;
          font-size: 1.25rem;
          line-height: 2rem;
        }
  
        .thumbVideo{
          position: relative;
          margin-bottom: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
  
          .thumb{
            width: 100%;
          }
  
          .btnPlay{
            width: 3rem;
            height: 3rem;
            position: absolute;
            
            img{
              animation: pulse 2s linear infinite;
            }
          }
  
          img{
            width: 100%;
            object-fit: contain;
          }
        }
  
        .button {
          position: relative;
          width: max-content;
          background-color: var(--app-orange);
          color: var(--app-black);
          font-family: "Arial", monospace, sans-serif;
          border-radius: 0.8rem;
          text-align: center;
          box-shadow: 0px 3px 4px 0px darkgrey;
          transition: all 0.2s ease;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.4rem;
          padding: 0.8rem 1rem;
          //color: white;
          margin: 0 auto;
          margin-bottom: 2rem;
        }
  
        .gallery {
          margin: 0 auto;
          position: relative;

          .galleryImg{
            width: 2.6rem;
            height: 2.6rem;
            pointer-events: none;
            position: absolute;
            bottom: 0;
            right: 0.25rem;
            animation: pulse 2s linear infinite;

            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
  
          /* Gallery */
          .layout-wrapper {
            position: relative;
            //display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            gap: 0.5rem;
  
            &:not(:first-of-type) {
              margin-top: 0.5rem;
            }
  
            &.special {
              .item {
                &:nth-child(3n + 1) {
                  grid-column: 1;
                  grid-row: 1 / span 1;
                }
              }
            }
  
            .item {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              min-height: 12rem;
              overflow: hidden;
  
              .image {
                position: absolute;
                width: 100%;
                height: 100%;
                margin: 0;
                object-fit: cover;
                object-position: center;
              }
  
              &:nth-child(3n + 1) {
              grid-column: 1;
              grid-row: 1 / span 2;
            }

            &:nth-child(3n + 2) {
              grid-column: 2 / span 1;
              grid-row: 1 / span 1;
              display: none;
            }

            &:nth-child(3n + 3) {
              grid-column: 2 / span 1;
              grid-row: 2 / span 1;
              display: none;
            }
            &:nth-child(3n + 4) {
              display: none;
            }
            &:nth-child(3n + 5) {
              display: none;
            }
            }
          }
        }
      }
    }
  
    .close {
      left: unset;
      right: 1rem;
    }
  }
  
  @keyframes pulse{
    0%{
      transform: scale(1);
      -webkit-transform: scale(1);
    }
    50%{
      transform: scale(1.3);
      -webkit-transform: scale(1.3);
    }
    100%{
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
  </style>
  