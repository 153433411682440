<template>
  <div class="ar-visor">
    <video class="video-background" autoplay muted playsinline></video>
    <a-scene
      vr-mode-ui="enabled: false;"
      renderer="logarithmicDepthBuffer: true;"
      embedded
      device-orientation-permission-ui="enabled: false"
      arjs="trackingMethod: best; sourceType: webcam; debugUIEnabled: false;"
      log="console: false"
    >
      <a-assets>
        <img id="arrow" src="img/arrow.png" />
      </a-assets>

      <a-camera position="0 1.5 2">
        <a-entity id="actualArrow" position="0 -1.2 -2" rotation="0 0 90">
          <a-plane
            src="#arrow"
            position="0.3 0 0"
            :rotation="`0 0 ${angle}`"
            scale="0.4 0.4 0.4"
            width="2"
            height="2"
            transparent="true"
            material="side: double;"
          ></a-plane>
        </a-entity>
      </a-camera>
    </a-scene>

    <div
      class="title-container"
      v-if="!showDisconectedMessage && !showFinishedRouteMessage"
    >
      {{ $t("ARVisor.title", { spot: nextPointName }) }}
    </div>

    <div class="disclaimer-container">
      {{ $t("ARVisor.disclaimer") }}
    </div>

    <button class="close-visor" @click="dismissView">
      <img
        src="img/02_generales/cerrar@2x.png"
        alt="close sidebar button"
      />
    </button>

    <AppAlert ref="appAlert" />
  </div>
</template>

<script>
import { Analytic } from "./../../public/js/analytics/Analytic.js";
import AppAlert from "@/components/AppAlert.vue";

var analytic = new Analytic({
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "ARVisor",
});

export default {
  name: "ARVisor",
  props: [
    "startPoint",
    "endPoint",
    "nextPointName",
    "userIsNearEnough",
    "isFinished",
  ],
  watch: {
    startPoint: function () {
      this.findPointDegree();
      this.findIndicatorAngle(this.compass, this.pointDegree);
    },
    userIsNearEnough: function () {
      this.$refs.appAlert.showAlert({
        type: "message",
        msg: this.$t("ARVisor.disconnected"),
      });
    },
    isFinished: function (val) {
      console.log("is route finished ", val);
      if (val == true) {
        this.$refs.appAlert.showAlert({
          type: "message",
          msg: this.$t("ARVisor.finishedRoute", { spot: this.nextPointName }),
        });
      }
    },
  },
  data() {
    return {
      angle: 0,
      isIOS: false,
      indicator: null,
      pointDegree: null,
      compass: null,
      location: null,
      videoConstraints: {
        video: {
          facingMode: {
            exact: "environment",
          },
        },
      },
    };
  },
  mounted() {
    analytic.registerEvent("AR Visor", "open");
    if (this.needsOnboarding()) {
      this.$store.commit("setAROnboardingAsShown");
      this.$refs.appAlert.showAlert({
        type: "message",
        msg: this.$t("ARVisor.onBoardingMessage"),
      });
    }

    this.loadCameraBackground();
    this.isIOS = this.checkForiOS();
    this.init();
  },
  components: {
    AppAlert,
  },
  methods: {
    init() {
      if (this.isIOS) {
        DeviceOrientationEvent.requestPermission()
          .then((response) => {
            if (response === "granted") {
              window.addEventListener("deviceorientation", this.handler, true);
            }
          })
          .catch((error) =>
            console.log("Ocurrió un error, inténtalo de nuevo", error)
          );
      } else {
        window.addEventListener(
          "deviceorientationabsolute",
          this.handler,
          true
        );
      }
      this.findPointDegree();
    },
    handler(e) {
      this.compass = e.webkitCompassHeading || Math.abs(e.alpha - 360);
      this.findIndicatorAngle(this.compass, this.pointDegree);
    },
    checkForiOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    findPointDegree() {
      const latitude = this.startPoint[0];
      const longitude = this.startPoint[1];
      this.pointDegree = this.calcDegreeToPoint(latitude, longitude);

      if (this.pointDegree < 0) {
        this.pointDegree = this.pointDegree + 360;
      }
    },
    findIndicatorAngle(angle, current) {
      var a = current - angle;
      a = ((a + 180) % 360) - 180;
      this.angle = a * -1;
    },
    calcDegreeToPoint(latitude, longitude) {
      if (this.endPoint == null) {
        return;
      }

      const phiK = (this.endPoint[0] * Math.PI) / 180.0;
      const lambdaK = (this.endPoint[1] * Math.PI) / 180.0;
      const phi = (latitude * Math.PI) / 180.0;
      const lambda = (longitude * Math.PI) / 180.0;

      const psi =
        (180.0 / Math.PI) *
        Math.atan2(
          Math.sin(lambdaK - lambda),
          Math.cos(phi) * Math.tan(phiK) -
            Math.sin(phi) * Math.cos(lambdaK - lambda)
        );

      return Math.round(psi);
    },
    loadCameraBackground() {
      if (
        !("mediaDevices" in navigator) ||
        !("getUserMedia" in navigator.mediaDevices)
      ) {
        return;
      }

      const video = document.querySelector(".video-background");
      if ("mediaDevices" in navigator && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: { exact: "environment" } } })
          .then((stream) => {
            video.srcObject = stream;
          })
          .catch((error) =>
            console.log("Ocurrió un error, inténtalo de nuevo ", error)
          );
      }
    },
    dismissView() {
      analytic.registerEvent("AR Visor", "closed");
      if (this.isIOS) {
        window.removeEventListener("deviceorientation", this.handler, true);
      } else {
        window.removeEventListener(
          "deviceorientationabsolute",
          this.handler,
          true
        );
      }
      this.$parent.dismissARVisor();
    },
    needsOnboarding() {
      let onBoardingAlreadyShown = this.$store.state.arOnboardingShown;
      return !onBoardingAlreadyShown;
    },
  },
};
</script>

<style lang="scss">
.ar-visor {
  position: absolute;
  background-color: black;
  background: repeating-linear-gradient(
    45deg,
    #606dbc,
    #606dbc 10px,
    #465298 10px,
    #465298 20px
  );
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;

  .video-background {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: auto;
    height: 100%;
  }

  a-scene {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .title-container {
    position: absolute;
    color: white;
    background-color: var(--app-modalBg);
    border: 0.2rem solid var(--app-modalBorder);
    border-radius: 0.5rem;
    width: 70%;
    flex: auto;
    top: 5vh;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0.5rem 1rem;
    font-family: "Arial-black", monospace, sans-serif;
    font-size: 1.2rem;
    z-index: 1;
  }

  .disclaimer-container {
    position: absolute;
    color: white;
    background-color: var(--app-modalBg);
    border-radius: 0.5rem;
    width: 95%;
    flex: auto;
    bottom: 1vh;
    left: 0;
    right: 0;
    text-align: left;
    margin: auto;
    padding: 0.5rem 1rem;
    font-family: "Arial", monospace, sans-serif;
    font-size: 0.8rem;
    z-index: 1;
  }

  .onBoarding {
    z-index: 12;
  }

  .close-visor {
    position: absolute;
    width: 2.6rem;
    height: 2.6rem;
    top: 1rem;
    right: 1rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    z-index: 402;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }
}

.fadeUp-enter-active {
  transition: all 0.3s ease;
}
.fadeUp-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.fadeUp-enter,
.fadeUp-leave-to {
  opacity: 0;
}
</style>
