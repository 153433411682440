import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexStorage = new VuexPersist({
  key: "vuex",
  storage: window.localStorage,
  reducer: (state) => ({
    locale: state.locale,
    validLocales: state.validLocales,
    isSidebarNeeded: state.isSidebarNeeded,
    parkData: state.parkData,
    routeId: state.routeId,
    numRoutes: state.numRoutes,
    currentRoute: state.currentRoute,
    currentChallenge: state.currentChallenge,
    onboardingState: state.onboardingState,
    nextRoute: state.nextRoute,
    routeToPaint: state.routeToPaint,
    finishedChallenges: state.finishedChallenges,
    shownRoutes: state.shownRoutes,
    finishedRoutes: state.finishedRoutes,
    dataEs: state.dataEs,
    dataEn: state.dataEn,
    dataPt: state.dataPt,
    dataFr: state.dataFr,
    showSplash: state.showSplash,
    showRating: state.showRating,
    arOnboardingShown: state.arOnboardingShown,
    condessaOnboardingShown: state.condessaOnboardingShown,
    condessaOnboardingShownSave: state.condessaOnboardingShownSave,
    winnerCode: state.winnerCode,
    typeRoute: state.typeRoute,
    routeSelected: state.routeSelected,
    playAudio: state.playAudio,
    arShow: state.arShow,
    crossShow: state.crossShow,
    mapRoute: state.mapRoute,
    intermediate: state.intermediate,
    home: state.home,
    entradaState: state.entradaState,
    onbCarrinho: state.onbCarrinho,
  }),
});

export default new Vuex.Store({
  state: {
    locale: null, // Lang of the app
    validLocales: ["es", "en", "pt", "fr"], // valid langs
    isSidebarNeeded: false,
    parkData: null, // localized park data
    routeId: null, // current route id
    numRoutes: null, // total routes in quest
    currentRoute: null, // current route
    currentChallenge: null, // current challenge data
    onboardingState: null, // state of onboarding is null or shown
    nextRoute: null, // next route data
    routeToPaint: null, // helpfull var to save an specific route to paint in map, that is not the next route
    finishedChallenges: [], // all finished challenges
    finishedRoutes: [], // all finished routes
    shownRoutes: [], // all shown routes
    dataEs: null, // the quest data in spanish
    dataEn: null, // the quest data in englinsh
    dataPt: null, // the quest data in portuguese
    dataFr: null, // the quest data in french
    showSplash: true, // the need to show the splash
    showRating: true, // the need to show the rating
    disabledLanguages: false, // helpfull to disable the button to change language in specific cases
    arOnboardingShown: false, // was the ar onboarding shown?,
    condessaOnboardingShown: false, // was the condessa onboarding shown?,
    condessaOnboardingShownSave: false,
    winnerCode: null, // the code to get the prize
    typeRoute: null,
    routeSelected: false,
    playAudio: false,
    arShow: false,
    crossShow: false,
    mapRoute: false,
    intermediate: null,
    home: null,
    entradaState: false,
    onbCarrinho: false,
  },
  mutations: {
    setOnboardingState(state, payload) {
      state.onboardingState = payload;
    },
    saveLocalizedParkData(state, payload) {
      state.dataEs = payload.es;
      state.dataEn = payload.en;
      state.dataPt = payload.pt;
      state.dataFr = payload.fr;
    },
    updateLocale(state, payload) {
      state.locale = payload;
    },
    setParkData(state, payload) {
      state.parkData = payload;
      state.numRoutes = state.parkData.pointsGeo.length;
    },
    setRouteId(state, payload) {
      state.routeId = payload;
    },
    setRouteAsShown(state, payload) {
      state.shownRoutes.push(payload);
    },
    setRouteAsFinished(state, payload) {
      // if payload is any of the 2 starts, add them both
      /*if (payload == "palacio") {
        state.finishedRoutes.push("entrada");
      } else if (payload == "entrada") {
        state.finishedRoutes.push("palacio");
      }*/
      state.finishedRoutes.push(payload);
    },
    currentRoute(state, payload) {
      state.currentRoute = payload;
    },
    currentChallenge(state, payload) {
      state.currentChallenge = payload;
    },
    nextRoute(state, payload) {
      state.nextRoute = payload;
    },
    routeToPaint(state, payload) {
      state.routeToPaint = payload;
    },
    registerFinishedChallenge(state, payload) {
      state.finishedChallenges.push(payload);
    },
    setShowSplash(state, payload) {
      state.showSplash = payload;
    },
    setShowRating(state, payload) {
      state.showRating = payload;
    },
    toggleDisabledLanguages(state, payload) {
      state.disabledLanguages = payload;
    },
    setAROnboardingAsShown(state) {
      state.arOnboardingShown = true;
    },
    setCondessaOnboardingAsShown(state) {
      state.condessaOnboardingShown = true;
    },
    setCondessaOnboardingAsShownSave(state) {
      state.condessaOnboardingShownSave = true;
    },
    setSidebarNeeded(state, payload) {
      state.isSidebarNeeded = payload;
    },
    winnerCode(state, payload) {
      state.winnerCode = payload;
    },
    typeRoute(state, payload) {
      state.typeRoute = payload;
    },
    routeSelected(state, payload) {
      state.routeSelected = payload;
    },
    playAudio(state, payload) {
      state.playAudio = payload;
    },
    arShow(state, payload) {
      state.arShow = payload;
    },
    crossShow(state, payload) {
      state.crossShow = payload;
    },
    mapRoute(state, payload) {
      state.mapRoute = payload;
    },
    intermediate(state, payload){
      state.intermediate = payload;
    },
    setHome(state, payload){
      state.home = payload;
    },
    entradaState(state, payload){
      state.entradaState = payload;
    },
    onbCarrinho(state, payload){
      state.onbCarrinho = payload;
    }
  },
  getters: {
    routeWasFinished: (state) => (id) => {
      return state.finishedRoutes.find((route) => route == id) != undefined;
    },
    allChallengesDone: (state) => {
      return state.finishedRoutes.length == state.numRoutes;
    },
    minPointsComplete: (state) => {
      let completed = state.finishedChallenges.length;
      let lastElement = state.finishedChallenges[state.finishedChallenges.length - 1];
      console.log("length", completed);
      console.log("last", lastElement);
      //cam_2 == palacio, cam_13 == chalet
      if(state.typeRoute == 1){
        return (completed == 6 && lastElement.id == "cam_2")
      }else{
        return (completed == 9 && (lastElement.id == "cam_2" || lastElement.id == "cam_13"))
      }
    }
    // allChallengesCorrect: (state) => {
    //   let completed = state.finishedChallenges.length;
    //   let correct = state.finishedChallenges.filter((ch) => ch.correct).length;

    //   return completed === correct;
    // },
  },
  actions: {},
  modules: {},
  plugins: [vuexStorage.plugin],
});
