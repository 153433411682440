<template>
  <div class="challenge-result">
    <div class="helper">
      <div v-if="correct" class="correct">
        <h2 class="section-title" v-html='this.setTitle'></h2>
        <div class="prize" :class="{ active }">
          <img
            :src="prizeImg"
            alt="prize image"
            :class="{ rotate: rotating }"
          />
          <span class="prize-name">
            {{ this.$store.state.currentRoute.name }}
          </span>
        </div>
      </div>

      <div
        v-if="!correct"
        class="incorrect"
        :class="{ centered: !hasNextRoute }"
      >
        <h2 class="section-title">{{ this.$t("correct-answer") }}</h2>
        <span v-if="type == 'quiz'" class="answer">{{ correctAnswer }}</span>
        <figure v-if="type == 'audio'" class="audioBtn" v-on:click="playAudio"><img src="@/assets/bocina.png"
            alt=""></figure>
        <span v-if="type == 'audio'" class="answer">{{ correctAnswer }}</span>
        <figure v-if="type == 'images'" class="answer badImage">
          <img :src="correctImage" />
        </figure>
        <div v-if="type == 'imagesv2'" class="answer wrapImgs">
          <figure v-for="ans in correctImages" v-bind:key="ans.id">
            <img :src="ans.img" />
          </figure>
        </div>
      </div>
    </div>

    <div class="orange-area">
      <div class="next-challenge-info isContainer">
        <div v-if="showNextRouteArea" class="next-challenge-info content">
          <span class="next-challenge" v-if="nextRoute.id != '-'">{{
            $t("next-challenge")
          }}</span>
          <span class="end-challenge" v-else>{{
            $t("complete-congrats2")
          }}</span>
          <span class="info-name" v-html="this.nextRouteInfo.name"></span>
          <span class="info-length" v-if="routeInformation">{{
            $t("aprox-distance", { distance: routeInformation.distance })
          }}</span>
          <span class="info-duration" v-if="routeInformation">{{
            $t("aprox-time", {
              time: routeInformation.time,
            })
          }}</span>
          <span class="info-slope" v-if="routeInformation">{{
            $t("aprox-slope", {
              slope: routeInformation.slope,
            })
          }}</span>
        </div>

        <div class="keep-enjoying" v-if="allChallengesDone || minPoints">
          <span class="keep-title">{{ $t("keep.msg1") }}</span>
        </div>
        <!--<div class="keep-enjoying" v-if="!allChallengesDone && !correct">
          <span class="keep-title">{{ $t("keep.msg2") }}</span>
        </div>-->

        <big-button type="answer" :onClick="advance">{{
          showNextRouteArea ? $t("go2") : $t("next")
        }}</big-button>
      </div>
    </div>

    <AppAlert ref="appAlert" />
  </div>
</template>

<script>
import Park from "@/models/Park.js";
import BigButton from "@/components/BigButton.vue";
import AppAlert from "@/components/AppAlert.vue";
import { Analytic } from "../../public/js/analytics/Analytic.js";
import { Howl } from "howler";

var analytic = new Analytic({
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Route",
});

export default {
  name: "ChallengeResult",
  components: {
    BigButton,
    AppAlert,
  },
  props: {
    correct: {
      type: Boolean,
    },
    advance: {
      type: Function,
      required: true,
    },
    hasNextRoute: {
      type: Boolean,
      required: true,
    },
    playAudio: {
      type: Function,
    },
  },
  watch: {
    "$store.state.parkData": function () {
      this.$i18n.locale = this.$store.state.locale;
      this.getNextRouteData();
    },
  },
  mounted() {
    this.$i18n.locale = this.$store.state.locale;
    this.parkData = new Park(this.$store.state.parkData);
    this.allChallengesDone = this.$store.getters.allChallengesDone;
    this.minPoints = this.$store.getters.minPointsComplete;
    this.getNextRouteData();

    //get actual info for the next route
    if (this.$store.state.nextRoute != null) {
      let currentRoute = this.$store.state.currentRoute.id;
      let nextRoute = this.nextRoute.id;
      this.routeInformation =
        this.parkData.routeInformation[currentRoute][nextRoute];
    }

    this.typeRoute = this.$store.state.typeRoute;

    if (this.allChallengesDone || this.minPoints) {
      analytic.registerEvent("All challenges", "complete");
      analytic.registerEvent("Finished routes", this.$store.state.finishedRoutes);
      if(this.typeRoute == 1){
        setTimeout(() => {
          this.$refs.appAlert.showAlert({
            type: "message",
            msg: this.$t("finish.alert"),
          });
        }, 1000);
      }else{
        setTimeout(() => {
          this.$refs.appAlert.showAlert({
            type: "message",
            msg: this.$t("finish.alertChalet"),
          });
        }, 1000);
      }
    }else{
      if(this.typeRoute == 1){
        if(this.$store.state.currentRoute.id == "palacio"){
          setTimeout(() => {
            this.$refs.appAlert.showAlert({
              type: "message",
              msg: this.$t("finish.palacio"),
            });
          }, 1000);
        }
      }else{
        if(this.$store.state.currentRoute.id == "palacio" || this.$store.state.currentRoute.id == "chalet"){
          setTimeout(() => {
            this.$refs.appAlert.showAlert({
              type: "message",
              msg: this.$t("finish.chalet"),
            });
          }, 1000);
        }
      }
    }

    this.entradaAudio = new Howl({
      src: ["audios/desafio_correcto.mp3"],
      loop: false,
      volume: 0.7
    });

    if(this.$store.state.currentRoute.id == "entrada"){
      this.entradaAudio.play();
    }

    setTimeout(() => {
      this.active = true;
    }, 500);
  },
  data() {
    return {
      parkData: null,
      active: false,
      rotating: false,
      nextRoute: null,
      routeInformation: null,
      allChallengesDone: false,
      minPoints: false,
      currentSound: null,
      nextRouteInfo: null,
      park: null,
      typeRoute: null,
      entradaAudio: null,
    };
  },
  computed: {
    setTitle(){
      if(this.$store.state.currentRoute.id == "entrada"){
        return this.$t("challenge-accomplished-entrada")
      }else{
        return this.$t("challenge-accomplished")
      }
    },
    type() {
      return this.$store.state.currentChallenge.type;
    },
    correctImage() {
      let correct = this.$store.state.currentChallenge.answers.find(
        (ans) => ans.isCorrect
      );
      return require(`@/assets/triviafotos/${this.$store.state.currentRoute.id}/${correct.answer}`);
    },
    correctImages() {
      var correct = [];
      var images = this.$store.state.currentChallenge.answers;
      images.forEach((element) => {
        if (element.isCorrect) {
          correct.push({id: element.id ,img: require(`@/assets/triviafotos/${this.$store.state.currentRoute.id}/${element.answer}`)});
        }
      });
      return correct;
    },
    correctAnswer() {
      let correct = this.$store.state.currentChallenge.answers.find(
        (ans) => ans.isCorrect
      );
      return correct.answer;
    },
    prizeImg() {
      let name = this.$store.state.currentRoute.prize;
      return require(`@/assets/06_camafeos/${name}_on.png`);
    },
    showNextRouteArea() {
      return this.nextRoute && (!this.allChallengesDone || !this.minPoints);
    },
  },
  methods: {
    getNextRouteData() {
      this.park = new Park(this.$store.state.parkData);
      if (this.$store.state.nextRoute != null) {
        this.nextRoute = this.parkData.getNextRouteInfo(
          this.$store.state.nextRoute.properties.endId
        );
        let id = this.$store.state.nextRoute.properties.endId;
        this.nextRouteInfo = this.park.getNextRouteInfo(id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$small: 300px;
$medium: 675px;

.challenge-result {
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--app-bg);

  .helper {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    margin: 7.5rem 0rem 0rem 0rem;
    overflow: auto;
    mask-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 2%,
      rgba(0, 0, 0, 1) 98%,
      rgba(0, 0, 0, 0) 100%
    );
    -webkit-mask-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 2%,
      rgba(0, 0, 0, 1) 98%,
      rgba(0, 0, 0, 0) 100%
    );

    @media screen and (max-height: $medium) {
      //margin: 7.5rem 0rem 0rem 0rem;
    }
  }

  .big-button {
    margin: 0.5rem 2.5rem;
    width: unset;
    background: var(--app-black);
  }

  .correct,
  .incorrect {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    padding-top: 1.5rem;

    @media screen and (max-height: $medium) {
      padding-top: 0.5rem;
    }

    .pulse {
      animation: pulse 4s infinite;
    }

    .audioBtn {
      width: 25%;
      height: 22%;
      margin-top: 1rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &.correct {
      justify-content: space-evenly;

      &.centered {
        justify-content: center;
      }

      .section-title {
        //font-size: 18px;
        //width: 100vw;
        //padding: 0 0.5rem;
      }

      .prize {
        position: relative;
        width: 100%;
        margin: 1rem 1.5rem;

        img {
          position: relative;
          width: 42%;
          object-fit: contain;
          transform: scale(0) translate3d(0, 0, 0);

          @media screen and (max-height: $medium) {
            width: 32%;
          }
        }

        &.active {
          img {
            animation-name: bounceIn;
            animation-duration: 0.8s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
          }
        }
      }

      .prize-name {
        position: relative;
        text-align: center;
        display: block;
        font-family: "Righteous", monospace, sans-serif;
        text-transform: uppercase;
        font-size: 0.8rem;
        max-width: 75%;
        left: 0;
        right: 0;
        margin: auto;
        color: #444444;
      }
    }

    &.incorrect {
      min-height: 15rem;

      .answer {
        padding: 2rem;
        font-size: 2rem;
        font-family: "Righteous", monospace, sans-serif;
        flex: 0.5;
        display: flex;
        align-items: center;
        color: #444444;

        @media screen and (max-height: $medium) {
          padding: 0.5rem;
        }

        img{

        }
      }

      .badImage{
        width: 55%;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
      }

      .wrapImgs{
        justify-content: space-evenly;

        figure{
          width: 30%;

          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
  }

  .section-title {
    padding: 0 0.5rem;
    font-family: "Righteous";
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #444444;
    font-weight: normal;
  }

  .orange-area {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    border-radius: 40px 40px 0px 0px;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.5);
    background-color: var(--app-orange);

    .next-challenge-info {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      color: #444444;

      .next-challenge {
        font-size: 1.3rem;
        font-weight: bold;
        margin: 1rem 0rem;
        text-transform: uppercase;

        @media screen and (max-height: $medium) {
          margin: 0.5rem 0rem;
          font-size: 1.1rem;
        }
      }

      .next-title {
        font-size: 1.3rem;
        font-weight: bold;
        margin: 1rem 0rem;
        text-transform: uppercase;

        @media screen and (max-height: $medium) {
          margin: 0.5rem 0rem;
          font-size: 1.1rem;
        }
      }

      .info-name {
        font-size: 1.8rem;
        font-weight: bold;
        margin: 1rem 0rem;
        text-align: center;
        max-width: 100%;

        @media screen and (max-height: $medium) {
          margin: 0rem;
          font-size: 1.5rem;
        }
      }

      .info-length {
        font-size: 1.2rem;
        margin-top: 1rem;
        text-align: center;

        @media screen and (max-height: $medium) {
          margin-top: 0.5rem;
          font-size: 1rem;
        }
      }

      .info-duration {
        font-size: 1.2rem;

        @media screen and (max-height: $medium) {
          font-size: 1rem;
        }
      }

      .info-slope {
        font-size: 1.2rem;
        margin-bottom: 1rem;

        @media screen and (max-height: $medium) {
          margin-bottom: 0.5rem;
          font-size: 1rem;
        }
      }
    }

    .keep-enjoying {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      font-family: "Arial-black", monospace, sans-serif;

      .keep-title {
        font-size: 1.2rem;
        margin: 0.5rem 0rem;
      }
    }

    .big-button {
      width: 14rem;
      box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
      background-color: #444444;
      border: 1px solid #3e3e3e;

      @media screen and (max-height: $medium) {
        padding: 0.5rem;
        //width: 90%;
      }
    }

    span {
      text-align: initial;
    }

    .end-challenge {
      font-size: 1rem;
      font-weight: bold;
      margin: 1rem 0rem;
    }
  }

  .instruction {
    margin: 1rem 0;
    font-size: 1rem;
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(0.1) translate3d(0, 0, 0);
    }

    50% {
      opacity: 0.9;
      transform: scale(1.02);
    }

    80% {
      opacity: 1;
      transform: scale(0.7);
    }

    100% {
      opacity: 1;
      transform: scale(1) translate3d(0, 0, 0);
    }
  }
}
</style>
