<template>
  <div class="onBoarding">
    <vue-scroll-snap :fullscreen="true" :horizontal="true">
      <div v-if="parkData" class="wrapper item">
        <div class="container lastO">
          <div class="bigspace"></div>
          <div class="text text1 title1 titleonb1" v-html="$t('onboarding1')"></div>
          <div class="text text1 space" v-html="$t('onboarding2')"></div>
          <div class="buttons">
            <div class="cont">
              <div class="left">
                <figure class="img1">
                  <img src="img/03_onboarding/on_mapacorto.png" alt="" />
                </figure>
              </div>
              <div class="right">
                <p style="color: #446836"><b>{{ $t("route2") }}</b></p>
                <span class="tiempo"><img src="img/03_onboarding/on_timer@3x.png" alt="">{{ $t("time2") }}</span>
                <span style="background-color: #446836" class="verMapa" v-on:click="openGallery('short')" :data-id="0"><img
                    src="img/03_onboarding/sr_location.png" alt="">{{ $t("goMap") }}</span>
              </div>
            </div>
            <div class="button btnLast ruta1" v-if="selectRoute == true" v-on:click="selectRuta(1)">{{
                  $t("select")
              }}</div>
          </div>
          <div class="text text1 space" v-html="$t('onboarding3')" v-if="isCarrinho == false"></div>
          <div class="buttons" v-if="isCarrinho == false">
            <div class="cont">
              <div class="left">
                <figure class="img1">
                  <img src="img/03_onboarding/on_mapacomp.png" alt="" />
                </figure>
              </div>
              <div class="right">
                <p style="color: #09569D"><b>{{ $t("route1") }}</b></p>
                <span class="tiempo tiempo1"><img src="img/03_onboarding/on_timer@3x.png" alt="">{{ $t("time1") }}</span>
                <span class="tiempo timeBack">{{ $t("time1A") }}</span>
                <span style="background-color: #09569D" class="verMapa" v-on:click="openGallery('long')" :data-id="0"><img
                    src="img/03_onboarding/sr_location.png" alt="">{{ $t("goMap") }}</span>
              </div>
            </div>
            <div class="button ruta2" v-if="selectRoute == true" v-on:click="selectRuta(2)">{{
                $t("select")
            }}</div>
          </div>
          <div class="bigspace"></div>
        </div>
      </div>
      <div v-if="parkData" class="wrapper item">
        <div class="container wrap2">
          <div class="text text1 title1" v-html="$t('onboarding4')"></div>
          <div class="text text1 rules" v-html="$t('onboarding5')"></div>
          <div class="text text1 rules" v-html="$t('onboarding6')"></div>
          <div class="text text1 rules" v-html="$t('onboarding7')"></div>
          <div class="text text1 rules" v-html="$t('onboarding8')"></div>
          <ul class="rulesA">
            <li><div class="text text1 rules" v-html="$t('onboarding8A')"></div></li>
            <li><div class="text text1 rules" v-html="$t('onboarding8B')"></div></li>
          </ul>
          <div class="text text1 rules" v-html="$t('onboarding9')"></div>
          <figure class="logo badge badgeob">
            <img src="img/menu/help/cm_on.png" alt="" />
          </figure>
        </div>
        <div class="bigspace"></div>
      </div>
      <div v-if="parkData" class="wrapper item">
        <div class="bigspace top"></div>
        <div class="text text1" v-html="$t('onboarding10')"></div>
        <figure class="logo">
          <img src="img/onboarding/on_help.png" alt="" />
        </figure>
        <!--<div class="text text1" v-html="$t('onboarding11')"></div>
        <figure class="logo">
          <img src="img/onboarding/on_wifibox.png" alt="" />
        </figure>
        <div class="text text1 red" v-html="$t('onboarding12')"></div>-->

        <div v-if="currentBullet < 2" class="bigspace"></div>

        <div class="big-button" :class="[{ disabled: typeRuta == null}]" v-if="currentBullet == 2 && selectRoute == true" v-on:click="advance()">{{ $t("start") }}</div>
        <div class="big-button" v-if="currentBullet == 2 && selectRoute == false" v-on:click="start()">{{ $t("start") }}</div>
      </div>
    </vue-scroll-snap>
    <transition name="fade" mode="out-in">
      <Gallery :images="plainGallery" :index="selectedImageIndex" v-if="galleryIsShown"></Gallery>
    </transition>
    <div v-if="currentBullet < 2" class="bullets">
      <div class="dot" :class="[{ active: currentBullet == 0 }]"></div>
      <div class="dot" :class="[{ active: currentBullet == 1 }]"></div>
      <div class="dot" :class="[{ active: currentBullet == 2 }]"></div>
    </div>

    <AppAlert ref="appAlert" />
  </div>
</template>

<script>
import VueScrollSnap from "vue-scroll-snap";
import Park from "@/models/Park.js";
import { Analytic } from "../../public/js/analytics/Analytic.js";
import Gallery from "@/components/Gallery.vue";
import AppAlert from "@/components/AppAlert.vue";

const $ = require('jquery');
window.$ = $;

var options = {
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Scanner",
};
var analytic = new Analytic(options);

export default {
  name: "OnBoarding",
  components: {
    VueScrollSnap,
    Gallery,
    AppAlert,
  },
  watch: {
    "$store.state.parkData": function () {
      this.parkData = this.$store.state.parkData;
      this.$i18n.locale = this.$store.state.locale;
    },
  },
  mounted() {
    this.isCarrinho = this.$store.state.onbCarrinho;
    analytic.registerEvent("OnBoarding", "open");
    var container = this.$el.querySelector(".scroll-snap-container");
    container.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
    var checkSelectRoute = this.$store.state.routeSelected;
    this.isSelected = this.$store.state.routeSelected;
    if (checkSelectRoute == false) {
      this.selectRoute = true;
    }
  },
  data() {
    return {
      parkData: this.$store.state.parkData,
      currentBullet: 0,
      selectRoute: false,
      checkSelectRoute: null,
      routeId: null,
      name: null,
      park: null,
      park0: null,
      route: null,
      information: null,
      challenge: null,
      currentIndex: null,
      isThisChallengeFinished: false,
      galleryIsShown: false,
      selectedImage: null,
      selectedImageIndex: null,
      gallery: [],
      typeRuta: null,
      isSelected: null,
      isCarrinho: false,
    };
  },
  methods: {
    handleScroll: function (e) {
      e.target;
      var containerScroll = this.$el.querySelector(
        ".scroll-snap-container"
      ).scrollLeft;
      var item = this.$el.querySelector(".item:nth-of-type(1)");
      this.currentBullet = Math.floor(containerScroll / item.offsetWidth);
      if(this.typeRuta == null && this.currentBullet == 2){
        setTimeout(() => {
          this.$refs.appAlert.showAlert({
            type: "select",
            msg: this.$t("noroute.alert"),
          });

          setTimeout(() => {
            $(".goTos1").off().on("click", function(){
              console.log("click");
              setTimeout(() => {
                const carousel = document.querySelector(".scroll-snap-container");
                const width = carousel.offsetWidth;
                carousel.scrollTo(carousel.scrollLeft + width * -2, 0);
              }, 200);
            });
          }, 200);
        }, 500);
      }
    },
    selectRuta: function(tipo){
      this.typeRuta = tipo;
      console.log(this.typeRuta);
      /*var buttonNext = document.querySelector(".big-button");
      buttonNext.classList.remove("disabled");*/
      var buttons = document.querySelectorAll(".button");
      buttons.forEach(function(el) {
        el.classList.remove("btnSelected");
      });
      if(tipo == 1){
        var button1 = document.querySelector(".ruta1");
        button1.classList.add("btnSelected");
      }else{
        var button2 = document.querySelector(".ruta2");
        button2.classList.add("btnSelected");
      }

      setTimeout(() => {
        const carousel = this.$el.querySelector(".scroll-snap-container");
        const width = carousel.offsetWidth;
        carousel.scrollTo(carousel.scrollLeft + width * 1, 0);
      }, 800);
    },
    start: function () {
      console.log("START");
      if (this.$store.state.routeId) {
        this.$router.replace("route");
      } else {
        this.$router.replace("scanner");
      }
    },
    advance: function () {
      console.log("FIRST");
      var site = this.typeRuta;
      analytic.registerEvent("OnBoarding", "closed");
      this.$store.commit("setOnboardingState", "shown");

      this.$store.commit("typeRoute", site);

      var longRoute = null;

      if (site == 2) {
        longRoute = "Long";
      } else {
        longRoute = "Short"
        var parkData1 = this.$store.state.parkData;
        parkData1.prizes = this.getPrizes();
        this.$store.commit("setParkData", parkData1);
      }

      analytic.registerEvent("Route selected", longRoute);

      var parkData0 = this.$store.state.parkData;
      parkData0.pointsGeo = this.getSpots();
      this.$store.commit("setParkData", parkData0);

      var parkData2 = this.$store.state.parkData;
      parkData2.routes = this.obtainNewRoutes(site);
      this.$store.commit("setParkData", parkData2);

      this.park = new Park(this.$store.state.parkData);

      //this.route = new Route(this.park.getRouteWithId(this.routeId));
      //this.$store.commit("currentRoute", this.route);
      //let nextRouteHelper = new NextRoute(this.route);
      //nextRouteHelper.getNextRouteData();
      //this.updateData();

      //if (this.$store.getters.routeWasFinished(this.routeId)) {
      //  return;
      //}
      //this.$store.commit("setRouteAsFinished", this.routeId);
      this.$store.commit("routeSelected", true);
      this.$router.push("route");
    },
    obtainNewRoutes(typeR) {
      var newRoute = [];
      this.$store.state.parkData.routes.map((route) => {
        if (newRoute.filter((point) => point.id == route.id).length == 0 && (route.typeRoute == typeR || route.typeRoute == 0)) {
          newRoute.push({
            audio: route.audio,
            challenge: route.challenge,
            desc: route.desc,
            gallery: route.gallery,
            id: route.id,
            name: route.name,
            order: route.order,
            prize: route.prize,
            tiporuta: route.tiporuta,
            typeRoute: route.typeRoute,
            quinta: route.quinta,
            chalet: route.chalet
          });
        }
      });

      return newRoute;
    },
    goMap: function (site) {
      this.$store.commit("typeRoute", site);
      var parkData0 = this.$store.state.parkData;
      parkData0.pointsGeo = this.getSpots();
      this.$store.commit("setParkData", parkData0);

      this.park = new Park(this.$store.state.parkData);

      this.pushRouteTo("Map", { type: "spots" });
    },
    getPrizes() {
      var newPrizes = [];
      var oldPrizes = this.$store.state.parkData.prizes;
      oldPrizes.map((a) => {
        if (a.typeRoute != 2) {
          newPrizes.push({
            id: a.id,
            img: a.img,
            name: a.name,
            route: a.route,
            typeRoute: a.typeRoute,
          });
        }
      });

      return newPrizes;
    },
    getSpots() {
      var spots = [];
      var typeR = this.$store.state.typeRoute;
      var routesGeo = this.$store.state.parkData.routesGeo
      routesGeo.map((route) => {
        if (spots.filter((spot) => spot.properties.id == route.properties.startId).length == 0 && (route.properties.typeRoute == typeR || route.properties.typeRoute == 0)) {
          spots.push({
            properties: {
              id: route.properties.startId,
              name: route.properties.start,
              type: route.properties.startPopupType,
              order: route.properties.startOrder,
              typeRoute: route.properties.typeRoute,
            },
            geometry: { coordinates: route.geometry.coordinates[0] },
          });
        }

        if (spots.filter((spot) => spot.id == route.endId).length == 0 && (route.properties.typeRoute == typeR || route.properties.typeRoute == 0)) {
          spots.push({
            properties: {
              id: route.properties.endId,
              name: route.properties.end,
              type: route.properties.endPopupType,
              order: route.properties.endOrder,
              typeRoute: route.properties.typeRoute,
            },
            geometry: {
              coordinates:
                route.geometry.coordinates[route.coordinates.length - 1],
            },
          });
        }
      });

      this.$store.state.numRoutes = spots.length;

      return spots;
    },
    updateData() {
      this.$nextTick();
      this.name = this.route.name;
      this.information = this.route.desc;
      this.gallery = this.route.gallery;
      this.challenge = this.route.challenge;
      this.isThisChallengeFinished = this.checkIfChallengeFinished();

      this.$root.$emit("initPlayerWith", this.routeAudio);
    },
    checkIfChallengeFinished() {
      let ocurrences = this.$store.state.finishedChallenges.find(
        (el) => el.challenge === this.route.challenge.id
      );

      return ocurrences != undefined;
    },
    pushRouteTo(route, params) {
      if (params) {
        this.$router.push({ name: route, query: params }).catch(() => { });
      } else {
        this.$router.push(route, params).catch(() => { });
      }
    },
    openGallery(e) {
      this.gallery = [];
      var lang = this.$store.state.locale;
      if (e == "long") {
        this.gallery.push({ "src": "mapas/mapaLargo_" + lang + ".jpg" });
      } else {
        this.gallery.push({ "src": "mapas/mapaCorto_" + lang + ".jpg" });
      }
      this.selectedImageIndex = 0;
      this.galleryIsShown = true;
    },
    closeGallery() {
      this.galleryIsShown = false;
    },
  },
  computed: {
    plainGallery() {
      if (this.gallery == null || this.gallery == undefined) {
        return [];
      }

      return this.gallery.map((item) => {
        return require(`@/assets/${item.src}`);
      });
    },
  },
};
</script>

<style lang="scss">
$small: 300px;
$medium: 600px;

.onBoarding {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: var(--app-bg);
  overflow: hidden;
  .scroll-snap-container {
    scrollbar-width: none;
  }

  .big-button {
    position: relative;
    margin: 0.5rem 0rem;
    padding: 1rem;
    width: 100%;
    font-size: 1.6rem;
    background-color: var(--app-orange);
    color: #444444;
    font-family: "Arial-black", monospace, sans-serif;
    border-radius: 0.8rem;
    text-transform: uppercase;
    text-align: center;
    box-shadow: 0px 3px 4px 0px darkgrey;
    transition: all 0.2s ease;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1.7rem;
    margin: 0;
    overflow: auto;
    max-height: 90%;

    .container {
      width: 100%;
      height: 91%;
      display: flex;
      flex-flow: column;
      align-items: center;
      overflow: auto;
      justify-content: center;
      //padding: 0 0.7rem;
    }

    .buttons {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .cont {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;

        .left {
          width: 42%;
          display: flex;
          flex-flow: column;
          justify-content: space-evenly;
          height: 100%;

          .img1 {
            width: 90%;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }

          span {
            font-size: 0.8rem;
            margin-top: 0.5rem;
            display: flex;
            align-items: center;
            font-family: "Arial", monospace, sans-serif;
            color: rgba(0, 0, 0, 0.7);

            img {
              width: 1rem;
              height: 1rem;
              object-fit: contain;
              object-position: center;
              margin-right: 0.3rem;
            }
          }
        }

        .right {
          width: 58%;
          display: flex;
          flex-flow: column;
          align-items: center;
          height: 100%;
          justify-content: center;

          p {
            font-size: 19px;
            text-align: center;
            margin-bottom: 0.4rem;
            font-family: "Arial", monospace, sans-serif;
            color: #336FB7;
          }

          span {
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            font-family: "Arial", monospace, sans-serif;
            color: rgba(0, 0, 0, 0.7);
            margin-bottom: 0.7rem;
            margin-top: 0;
            line-height: 24px;
            margin-right: 0;
            margin-left: 0;

            img {
              width: 1rem;
              height: 1rem;
              object-fit: contain;
              object-position: center;
              margin-right: 0.3rem;
            }
          }

          .verMapa{
            background: #444444;
            border-radius: 6px;
            color: white;
            padding: 0 0.5rem;
            box-shadow: 0px 3px 4px 0px darkgrey;
          }

          .tiempo{
            font-weight: 600;
          }

          .tiempo1{
            margin-bottom: 0;
          }

          .timeBack{
            font-size: 13px;
          }
        }
      }

      .button {
        position: relative;
        width: auto;
        background-color: var(--app-orange);
        color: #444444;
        font-family: "Arial-black", monospace, sans-serif;
        border-radius: 0.8rem;
        text-align: center;
        box-shadow: 0px 3px 4px 0px darkgrey;
        transition: all 0.2s ease;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        padding: 0.3rem 0.5rem;
        //color: white;
        text-transform: uppercase;
        margin-top: 0.5rem;
      }

      .btnLast{
        //margin-bottom: 1rem;
      }

      .btnSelected{
        border: 2px solid black;
        box-shadow: none;
        transform: translateY(0.2rem);
      }
    }

    .wrap2{
      justify-content: space-between;
      margin-top: 1rem;
    }

    .lastO {
      //height: 100%;
      //justify-content: space-between;

      @media screen and (max-height: $medium) {
        //V4
        //justify-content: space-between;
        padding-bottom: 10px;
        padding-top: 10px;
      }

      .lastT {
        margin-top: 10rem;
      }
    }

    .logo {
      position: relative;
      width: initial;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        position: relative;
        height: auto;
        @media screen and (max-height: $medium) {
          //width: 90px;
          width: 55px;
        }
        @media screen and (min-height: $medium) {
          //width: 125px;
          width: 85px;
        }
      }
    }

    .badge {
      margin-top: 1rem;
      img {
        @media screen and (max-height: $medium) {
          width: 70px;
        }
        @media screen and (min-height: $medium) {
          width: 100px;
        }
      }
    }

    .badgeob{
      display: block;
      position: unset;
    }

    .logoruta{
      margin-bottom: 5%;
      width: 20%;
    }

    .logo3 {
      margin-top: 1.5rem;
    }

    .space {
      margin: 1rem 0rem 0.3rem !important;
      width: 100%;
      text-align: left;
    }

    .img1 {
      width: 75%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .title1 {
      font-size: 1.5rem !important;
      text-align: center;
      align-self: center;
      font-weight: 700;
      color: #444444;
      width: 100%;
    }

    .titleonb1{
      //margin-bottom: 1rem !important;
    }

    .text {
      text-align: left;
    }
    .text1 {
      font-size: 1.25rem;
      margin: 0rem;
      b{
        color: #444444;
      }
      @media screen and (max-height: $medium) {
        //font-size: 1.2rem;
        margin: 0rem;
      }
    }

    .rulesA{
      margin-left: 3rem;
    }

    .rules{
      display: flex;
      flex-flow: row;
      margin-top: 1rem;
      width: 100%;

      .regla{
        width: 100%;
        text-align: left;

        b{
          color: #444444;
        }
      }

      img{
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }
    }

    .red{
      width: 90%;

      .red{
        width: 40%;
      }
    }

    .text1 > span {
      width: 35%;
      display: inline-block;
      font-family: "Arial", monospace, sans-serif;
      text-align: right;
      &.center {
        width: auto;
        text-align: center;
        font-size: 1.8rem;
        margin-bottom: 0.5rem;
        border-bottom: 4px solid #e4a709;
        color: #444444;
      }
    }
    .title {
      font-size: 2.2rem;
      margin: 1rem;
      text-transform: uppercase;
      @media screen and (max-height: $medium) {
        font-size: 1.7rem;
        margin: 0rem;
      }
    }
    .title2 {
      font-size: 1.5rem;
      margin: 1rem;
      @media screen and (max-height: $medium) {
        margin: 0rem;
      }
    }

    .text-wrapper {
      flex: 1;
      margin: 0.5rem 0;
      padding: 0 0.5rem;
      padding-right: 0.5rem;
      overflow: auto;
    }

    p {
      text-align: initial;
    }
  }

  .bigspace {
    height: 65px;
    margin: 0.5rem 0;
    &.top {
      height: 25px;
    }
  }
  .bullets {
    position: absolute;
    bottom: 0.5rem;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 100%;

    .dot {
      width: 22px;
      height: 22px;
      margin-left: 10px;
      padding: 5px;
      cursor: pointer;
      background-image: url("./../assets/dots/on_selec2.png");
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: 8px;
      &.active {
        width: 35px;
        height: 35px;
        margin-bottom: 0rem;
        background-image: url("./../assets/dots/on_selec1.png");
      }
    }
  }
}

@media screen and (max-height: 700px) {
  .onBoarding{
    .wrapper{
      .wrap2{
        justify-content: space-between !important;
        margin-top: 1rem;

        .textA{
          margin-bottom: 1.5rem;
        }
      }
      .lastO{
        justify-content: space-between !important;
      }
    }
  }
}
</style>
