<template>
  <div class="quiz-images">
    <div v-if="question" class="question-wrapper">
      <p v-html="question"></p>
    </div>
    <div v-if="answers" class="answers-wrapper images">
      <div
        class="figure"
        ref="answer"
        v-for="ans in altAnswers"
        v-bind:key="ans.id"
        :data-opt="ans.id"
        type="answer"
        :selectable="true"
        v-on:click="chosenAnswer"
        :class="{ selected: ans.id === selected }"
      >
        <img class="image" :data-opt="ans.id" :src="ans.answer" />
      </div>
      <figure class="zoom">
        <img :src="require('@/assets/desafio_galeria.png')" alt="">
      </figure>
    </div>
    <transition name="fade" mode="out-in">
      <Gallery :images="plainGallery" :index="selectedImageIndex" v-if="galleryIsShown"></Gallery>
    </transition>
  </div>
</template>
<script>
import Gallery from "@/components/Gallery.vue";
import { Analytic } from "../../public/js/analytics/Analytic.js";
import { Howl } from "howler";
var options = {
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Trivia",
};
var analytic = new Analytic(options);
export default {
  name: "ImageTrivia",
  components: {
    Gallery,
  },
  props: {
    challengeId: {
      type: Number,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
    onFinished: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
      altAnswers: [],
      galleryIsShown: false,
      selectedImage: null,
      selectedImageIndex: null,
      gallery: [],
      success: null,
      fail: null,
    };
  },
  mounted() {
    analytic.registerEvent("ImgTrivia", "open");
    let route = this.$store.state.currentRoute;

    this.fail = new Howl({
      src: ["audios/desafio_incorrecto.mp3"],
      loop: false,
      volume: 0.7
    });
    this.success = new Howl({
      src: ["audios/desafio_correcto.mp3"],
      loop: false,
      volume: 0.7
    });

    this.answers.forEach((answer) => {
      let imageval = answer.answer;
      //let imagevaloff = imageval.replace(".png", "_off.png");
      var altans = {
        id: answer.id,
        answer: require(`@/assets/triviafotos/${route.id}/${imageval}`),
        //answeroff: require(`@/assets/triviafotos/${route.id}/${imagevaloff}`),
      };
      this.altAnswers.push(altans);
      this.gallery.push({ "src": imageval });
    });

    document.querySelectorAll('.zoom').forEach(item => {
      item.addEventListener('click', event => {
        console.log(event);
        this.selectedImageIndex = 0;
        this.openGallery();
      })
    })
  },
  computed: {
    challengeAudio() {
      return this.challenge.audio;
    },
    challengeFinishedAudio() {
      return this.challenge.finishedAudio;
    },
    plainGallery() {
      let route = this.$store.state.currentRoute;
      if (this.gallery == null || this.gallery == undefined) {
        return [];
      }

      return this.gallery.map((item) => {
        return require(`@/assets/triviafotos/${route.id}/${item.src}`);
      });
    },
  },
  methods: {
    shuffle(a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    },
    chosenAnswer(evt) {
      this.$root.$emit("togglePointerEvents", false);
      let chosen = this.answers.find((ans) => ans.id == evt.target.dataset.opt);
      this.selected = chosen.id;
      var images = this.$el.querySelectorAll("img.image");
      images.forEach((element) => {
        if (element.dataset.opt != chosen.id) {
          /*let tmpans = this.altAnswers.find(
            (ans) => ans.id == element.dataset.opt
          );
          element.setAttribute("src", tmpans.answeroff);*/
          element.style.filter = "grayscale(100%)";
          element.style.transform = "scale(0.95)";
        }
      });
      let saveObj = {
        id: this.$store.state.currentRoute.prize,
        challenge: this.challengeId,
        correct: chosen.isCorrect,
      };
      if (chosen.isCorrect) {
        this.success.play();
      } else {
        this.fail.play();
      }
      if (!this.containsObject(saveObj, this.$store.state.finishedChallenges)) {
        this.$store.commit("registerFinishedChallenge", saveObj);
      }
      setTimeout(() => {
        this.$root.$emit("togglePointerEvents", true);
        var routeId = this.$store.state.currentRoute.id;
        analytic.registerEvent(
          `ImgTrivia: ${routeId} `,
          ` result: ${chosen.isCorrect}`
        );
        this.onFinished(chosen.isCorrect);
      }, 1500);
      var todasRutas = this.$store.state.finishedRoutes;
      if((todasRutas.includes("entrada") == false)){
        this.$store.state.finishedRoutes.push("entrada");
          let saveObjE = {
            id: "cam_1",
            challenge: 1,
            correct: true,
          };

          this.$store.commit("registerFinishedChallenge", saveObjE);
        
        this.$store.commit("entradaState", true);
      }
    },
    containsObject(obj, list) {
      return list.find((item) => item.id === obj.id) !== undefined;
    },
    openGallery() {
      this.galleryIsShown = true;
    },
    closeGallery() {
      this.galleryIsShown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.quiz-images {
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background-color: var(--app-bg);

  .question-wrapper {
    width: 100%;
    margin: 0 0 1rem 0;
    overflow: auto;
    display: flex;
    flex-flow: column;

    p {
      font-family: "Arial-Black", monospace, sans-serif;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 700;
      color: #444444;
    }
  }

  .answers-wrapper {
    /* max-height: 50%; */
    width: 100%;
    max-width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);
    margin-right: 2rem;

    .zoom {
        position: fixed;
        bottom: -12rem;
        top: 0;
        right: 3%;
        width: 2.6rem;
        height: 2.6rem;
        z-index: 990;
        margin: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

    .figure {
      width: 95%;
      height: 95%;
      margin: 0.1rem;
      .image {
        width: 100%;
      }
    }
  }
}
</style>
